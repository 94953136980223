<template>
    <v-fade-transition>
        <div class="col-12 pa-0">
            <v-sheet class="dense-inputs">
                <v-row>
                    <v-col class="col-1_5 col-xl-2 d-flex flex-row align-end">
                        <div class="d-flex flex-column">
                            <span class="font-sm">{{ $t('message.warehouse') }}</span>
                            <v-select
                                :items="filteredOffices"
                                class="filter-width-100 bg-lightblue"
                                dense
                                hide-details="auto"
                                item-value="Office.code"
                                item-text="Office.code"
                                solo
                                v-model="filterValues.warehouse"
                            />
                        </div>
                        <v-btn class="ml-3 px-2" @click="fetchReport()" :loading="loading.masterList">{{ $t('message.refetch') }}</v-btn>
                    </v-col>
                    <v-col class="col-10_5 col-xl-10 d-flex flex-row align-end">
                        <div class="d-flex flex-column">
                            <span class="font-sm">{{ exRateTitle[filterValues.warehouse] }}</span>
                            <div class="d-flex flex-row align-end">
                                <v-text-field
                                    class="filter-width-100 bg-lightorange"
                                    dense
                                    hide-details="auto"
                                    placeholder="XXXXXXXX"
                                    solo
                                    type="number"
                                    v-model="currentExchangeRates__MY"
                                    v-if="filterValues.warehouse == 'MY'"
                                />
                                <v-text-field
                                    class="filter-width-125 bg-lightorange"
                                    dense
                                    hide-details="auto"
                                    placeholder="XXXXXXXX"
                                    solo
                                    type="number"
                                    v-model="currentExchangeRates__ID"
                                    v-if="filterValues.warehouse == 'ID'"
                                />
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon @click="getUpdatedExRate()" v-on="on" class="ml-1">
                                            <v-icon large class="green--text text--darken-1">mdi-swap-horizontal</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.getUpdatedExchangeRate') }}</span>
                                </v-tooltip>
                                <span class="font-italic">{{ '(last updated: ' + (currentExchangeRates__lastUpdate ? formatDate(currentExchangeRates__lastUpdate) : '' ) + ')'}}</span>
                            </div>
                        </div>
                        <div class="ml-5">
                            <v-btn @click="openTotalsDialog()">{{ $t('message.showTotals') }}</v-btn>
                        </div>
                        <v-spacer/>
                        <div class="d-flex flex-column ml-5">
                            <span class="font-sm">{{ $t('message.export') }}</span>
                            <div class="d-flex flex-row">
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn
                                                    color="default"
                                                    v-bind="attrs"
                                                    v-on="{ ...tooltip, ...menu }"
                                                >
                                                    <v-icon class="far fa-file-pdf" color="red darken-1"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>PDF</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list>
                                        <v-list-item class="text-no-wrap" @click="exportList('available','pdf')">{{ 'PDF ' + $t('message.availableList') }}</v-list-item>
                                        <template v-for="item in priceMargins">
                                            <v-list-item class="text-no-wrap" @click="exportList('priceList_' + item.PriceMargin.margin.toString(),'pdf','pm_' + item.PriceMargin.margin.toString())">{{ 'PDF ' + $t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%' }}</v-list-item>
                                        </template>
                                    </v-list>
                                </v-menu>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on: menu, attrs }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn
                                                    color="default"
                                                    class="ml-3"
                                                    v-bind="attrs"
                                                    v-on="{ ...tooltip, ...menu }"
                                                >
                                                    <v-icon class="far fa-file-excel" color="green darken-2"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Excel</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list>
                                        <v-list-item class="text-no-wrap" @click="exportList('available','excel')">{{ 'Excel ' + $t('message.availableList') }}</v-list-item>
                                        <template v-for="item in priceMargins">
                                            <v-list-item class="text-no-wrap" @click="exportList('priceList_' + item.PriceMargin.margin.toString(),'excel','pm_' + item.PriceMargin.margin.toString())">{{ 'Excel ' + $t('message.priceList') + ' ' + item.PriceMargin.margin.toString() + '%' }}</v-list-item>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
            <v-overlay
                :value="loading.masterList"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        />
                    </v-col>
                </v-row>
            </v-overlay>
            <v-row class="no-gutters fill-height flex-row">
                <v-col class="col-1_5 col-xl-2 pr-2 mt-2 stock-filters" :style="cssVars">
                    <span>{{ $t('message.filters') }}</span>
                    <v-autocomplete
                        :items="filterOptions.product"
                        :placeholder="$t('message.product')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('product',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <v-autocomplete
                        :items="filterOptions.species"
                        :placeholder="$t('message.species')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('species',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <v-autocomplete
                        :items="filterOptions.thickness"
                        :placeholder="$t('message.thickness')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('thickness',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false})})"
                    />
                    <v-autocomplete
                        :items="filterOptions.grade"
                        :placeholder="$t('message.grade')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('grade',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false})})"
                    />
                    <v-autocomplete
                        :items="filterOptions.supplier"
                        :placeholder="capitalize($t('message.supplier'))"
                        class="font-sm"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('supplier',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <v-autocomplete
                        :items="filterOptions.certification"
                        :placeholder="$t('message.claim')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('certification',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <v-autocomplete
                        :items="filterOptions.bundleNo"
                        :placeholder="$t('message.bundleNo')"
                        class="font-sm mt-2"
                        clearable
                        dense
                        hide-details="auto"
                        multiple
                        solo
                        @change="filterList('bundleNo',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <v-autocomplete
                            :items="filterOptions.age"
                            :placeholder="capitalize($t('message.days'))"
                            class="font-sm"
                            clearable
                            dense
                            hide-details="auto"
                            multiple
                            solo
                            @change="filterList('age',$event).then(() => { calculateTotalVolumes().then(() => {loading.masterList = false}).catch(() => {loading.masterList = false}) }).catch(() => {loading.masterList = false})"
                    />
                    <div class="d-flex flex-row align-center font-sm mt-5">
                        <v-icon small color="red lighten-1" class="ml-3">fiber_manual_record</v-icon>
                        <span class="ml-5">{{ $t('message.cannotCalculateCurrentBuyingPrice') }}</span>
                    </div>
                </v-col>
                <v-col class="col-10_5 col-xl-10">
                    <v-data-table
                            :fixed-header="true"
                            :headers="headers"
                            :height="tableHeight"
                            :items="filteredItems"
                            :items-per-page="-1"
                            :sort-by="'Stock.age'"
                            :selectable-key="'Stock.id'"
                            calculate-widths
                            class="mt-3 appic-table-light specification-table"
                            dense
                            hide-default-footer
                            item-key="Stock.id"
                            multi-sort
                            ref="masterListTable"
                            show-select
                            v-model="selectedStock"
                    >
                        <template v-slot:item.Stock.age="{ item }">
                            <div class="font-sm pt-2" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                            <div class="font-sm pt-2 red--text text--darken-3" v-else>180+</div>
                        </template>
                        <template v-slot:item.Stock.quantity="{ item }">
                            <div class="text-no-wrap text-end">{{ numberFormat(item.Stock.quantity, '0,0') }}</div>
                        </template>
                        <template v-slot:item.Stock.volume="{ item }">
                            <div class="text-end">{{ item.Stock.volume }}</div>
                        </template>
                        <template v-slot:item.Stock.uofmVolume="{ item }">
                            <div class="text-start">{{ item.Stock.uofmVolume }}</div>
                        </template>
                        <template v-slot:item.Stock.currencyLocal="{ item }">
                            <span>{{ item.Stock.currencyLocal }}</span>
                        </template>
                        <template v-slot:item.Stock.costPriceLocal="{ item }">
                            <div class="text-end">{{ numberFormat(item.Stock.costPriceLocal, '0,0') }}</div>
                        </template>
                        <template v-slot:item.Stock.costPriceCurrentLocal="{ item }">
                            <div class="d-flex flex-row justify-content-end" v-if="item.Stock.cannotCalculateCurrentPrice">
                                <v-icon small color="red lighten-1">fiber_manual_record</v-icon>
                                <span>{{ numberFormat(item.Stock.costPriceCurrentLocal, '0,0') }}</span>
                            </div>
                            <div v-else class="text-end">{{ numberFormat(item.Stock.costPriceCurrentLocal, '0,0') }}</div>
                        </template>
                        <template v-slot:item.Stock.currencySelling="{ item }">
                            <div class="text-end font-weight-bold">{{ item.Stock.currencySelling }}</div>
                        </template>
                        <template v-slot:item.Stock.suggestedSellingPrice_1="{ item }">
                            <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[0].PriceMargin.margin).toString()], '0,0') }}</div>
                        </template>
                        <template v-slot:item.Stock.suggestedSellingPrice_2="{ item }">
                            <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[1].PriceMargin.margin).toString()], '0,0') }}</div>
                        </template>
                        <template v-slot:item.Stock.suggestedSellingPrice_3="{ item }">
                            <div class="text-end font-weight-bold">{{ numberFormat(item.Stock.suggestedSellingPrice['pm_' + parseInt(priceMargins[2].PriceMargin.margin).toString()], '0,0') }}</div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <AvailableListTotals
                :dialog.sync="totalsDialog"
                :totals="totalVolumes"
                @dialog-closed="totalsDialogClosed"
            />
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import {capitalize, formatDate, numberFormat} from "Helpers/helpers";

const AvailableListTotals = () => import("Components/Appic/StockReports/AvailableListTotals");
const ReserveStock = () => import("Components/Appic/ReserveStock");

export default {
    name: "AvailableList",
    components: {
        AvailableListTotals,
        ReserveStock
    },
    data(){
        return {
            exRateTitle: {
                MY: "MYR -> 1 USD",
                ID: "IDR -> 1 USD",
            },
            filteredItems: [],
            filterOptions: {
                age: [],
                bundleNo: [],
                certification: [],
                costPriceCurrentLocal: [],
                costPriceLocal: [],
                grade: [],
                length: [],
                product: [],
                quantity: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                width: []
            },
            filterValues: {
                age: [],
                bundleNo: [],
                certification: [],
                costPriceCurrentLocal: [],
                costPriceLocal: [],
                grade: [],
                length: [],
                product: [],
                quantity: [],
                species: [],
                supplier: [],
                thickness: [],
                volume: [],
                warehouse: 'MY',
                width: []
            },
            items: [],
            loading: {
                masterList: false
            },
            priceMargins: [
                {
                    PriceMargin: {
                        id: 1,
                        title: 'Margin 0%',
                        margin: 0
                    }
                },
                {
                    PriceMargin: {
                        id: 2,
                        title: 'Margin 0%',
                        margin: 0
                    }
                },
                {
                    PriceMargin: {
                        id: 3,
                        title: 'Margin 0%',
                        margin: 0
                    }
                }
            ],
            reserveDialog: false,
            selectedStock: [],
            selectedStockId: null,
            selectedStockBundleNo: null,
            selectedStockReservedBuyerId: null,
            spacerHeight: 10,
            tableHeight: '500',
            tableFooterHeight: 0,
            totalBuyingCosts: {},
            totalBuyingCostsByAge: {
                fresh: 0,
                stale: 0
            },
            totalBuyingCostsByProduct: [],
            totalQuantities: [],
            totalVolumes: [],
            totalsDialog: false,
            updateReserve: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs',
            stockReportsCurrentWarehouse: 'stockReportsCurrentWarehouse'
        }),
        ...mapFields('stock', {
            currentExchangeRates__lastUpdate: 'currentExchangeRates.lastUpdate',
            currentExchangeRates__MY: 'currentExchangeRates.MY',
            currentExchangeRates__ID: 'currentExchangeRates.ID',
            currentForwarderCosts__hardwood: 'currentForwarderCosts.hardwood',
            currentForwarderCosts__hdf: 'currentForwarderCosts.hdf',
            currentForwarderCosts__hpl: 'currentForwarderCosts.hpl',
            currentForwarderCosts__mdf: 'currentForwarderCosts.mdf',
            currentForwarderCosts__particleBoard: 'currentForwarderCosts.particleBoard',
            currentForwarderCosts__plywood: 'currentForwarderCosts.plywood',
            currentForwarderCosts__softwood: 'currentForwarderCosts.softwood',
            currentForwarderCosts__veneer: 'currentForwarderCosts.veneer',
            currentImportDuty__MY: 'currentImportDuties.MY',
            currentImportDuty__ID: 'currentImportDuties.ID',
            currentWareHouseSalesMargins__one: 'currentWareHouseSalesMargins.marginOne',
            currentWareHouseSalesMargins__two: 'currentWareHouseSalesMargins.marginTwo',
            currentWareHouseSalesMargins__three: 'currentWareHouseSalesMargins.marginThree',
            currentWareHouseSalesMargins__four: 'currentWareHouseSalesMargins.marginFour',
            currentWareHouseCost: 'currentWareHouseCost'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'offices'
        ]),
        cssVars() {
            return {
                '--table-height': this.tableHeight.toString() + 'px'
            }
        },
        currencyFormat() {
            let currencyFormat = '0,0.00'
            if(this.filterValues.warehouse === 'ID') currencyFormat = '0,0'
            return currencyFormat
        },
        filteredOffices () {
            return this.offices.filter( o => [1,21].includes(o.Office.id))
        },
        headers() {
            return [
                {
                    id: 1,
                    text: capitalize(this.$t('message.days')),
                    value: 'Stock.age',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 2,
                    text: this.$t('message.supplier'),
                    value: 'Stock.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'Stock.product',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 4,
                    text: this.$t('message.species'),
                    value: 'Stock.species',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 5,
                    text: this.$t('message.claim'),
                    value: 'Stock.certification',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 6,
                    text: this.$t('message.bundleNo'),
                    value: 'Stock.bundleNo',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 7,
                    text: this.$t('message.grade'),
                    value: 'Stock.grade',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 8,
                    text: 'T',
                    value: 'Stock.thickness',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 9,
                    text: 'W',
                    value: 'Stock.width',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 10,
                    text: 'L',
                    value: 'Stock.length',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 11,
                    text: this.$t('message.pcs'),
                    value: 'Stock.quantity',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 12,
                    text: this.$t('message.qty'),
                    value: 'Stock.volume',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 13,
                    text: this.$t('message.unit'),
                    value: 'Stock.uofmVolume',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: true,
                    searchable: true
                },
                {
                    id: 14,
                    text: this.$t('message.cur'),
                    value: 'Stock.currencyLocal',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 15,
                    text: this.$t('message.buyingPriceLocal'),
                    value: 'Stock.costPriceLocal',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 16,
                    text: this.$t('message.currentBuyingPriceLocal'),
                    value: 'Stock.costPriceCurrentLocal',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 17,
                    text: this.$t('message.sellingPriceCurrency'),
                    value: 'Stock.currencySelling',
                    class: 'light-green lighten-3 pa-1 width-1-pct',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                },
                {
                    id: 18,
                    text: 'SP: ' + this.priceMargins[0].PriceMargin.margin.toString() + '%',
                    value: 'Stock.suggestedSellingPrice_1',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                },
                {
                    id: 19,
                    text: 'SP: ' + this.priceMargins[1].PriceMargin.margin.toString() + '%',
                    value: 'Stock.suggestedSellingPrice_2',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                },
                {
                    id: 20,
                    text: 'SP: ' + this.priceMargins[2].PriceMargin.margin.toString() + '%',
                    value: 'Stock.suggestedSellingPrice_3',
                    class: 'light-green lighten-3 pa-1 text-end',
                    sortable: false,
                    searchable: false,
                    cellClass: 'amber lighten-3'
                }
            ]
        }
    },
    methods: {
        ...mapActions('stock',{
            resetWareHouseValues: 'resetWareHouseValues'
        }),
        calculateTotalVolumes() {
            return new Promise((resolve, reject) => {
                try {
                    const result = [...this.filteredItems.reduce((r, o) => {
                        const key = o.Stock.product + '-' + o.Stock.certification + '-' + o.Stock.species + '-' + o.Stock.uofmVolume
                        const item = r.get(key) || Object.assign({}, {
                            product: o.Stock.product,
                            certificate: o.Stock.certification,
                            species: o.Stock.species,
                            uofm: o.Stock.uofmVolume,
                            localCurrency: o.Stock.currencyLocal
                        }, {
                            total: 0,
                            totalCurrentBuyingPriceLocal: 0,
                        })
                        item.total += o.Stock.volume
                        item.totalCurrentBuyingPriceLocal += o.Stock.costPriceCurrentLocal
                        return r.set(key, item)
                    }, new Map).values()]
                    this.totalVolumes = result
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        capitalize,
        clearFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            this.filterOptions[b] = []
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        async exportList(report, reportType, priceMargin = null) {
            const date = new Date()
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const fullDate = year + "-" + month + "-" + day

            let reportFileName = 'Stock_Available_List_' + fullDate
            let url = '/reports/stocks/available/export'
            let headers = [
                this.$t('message.days'),
                this.$t('message.product'),
                this.$t('message.species'),
                this.$t('message.claim'),
                this.$t('message.bundleNo'),
                this.$t('message.grade'),
                'T',
                'W',
                'L',
                this.$t('message.pcs'),
                this.$t('message.qty'),
                this.$t('message.unit'),
                this.$t('message.cur'),
                this.$t('message.buyingPriceLocal'),
                this.$t('message.currentBuyingPriceLocal'),
                this.$t('message.cur')
            ]
            this.priceMargins.forEach(pm => {
                headers.push('SP: ' + pm.PriceMargin.margin.toString() + '%')
            })
            if(report !== 'available') {
                reportFileName = 'Price_List_' + fullDate
                url = '/reports/stocks/available/price-list/export'
                headers = [
                    this.$t('message.days'),
                    this.$t('message.product'),
                    this.$t('message.species'),
                    this.$t('message.claim'),
                    this.$t('message.bundleNo'),
                    this.$t('message.grade'),
                    'T',
                    'W',
                    'L',
                    this.$t('message.pcs'),
                    this.$t('message.qty'),
                    this.$t('message.unit'),
                    this.$t('message.cur'),
                    'SP'
                ]
            }
            let reportFileExtension = '.pdf'
            let reportFileType = 'application/pdf'
            if(reportType === 'excel'){
                reportFileExtension = '.xlsx'
                reportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
            try {
                this.$toast(this.$t('message.successes.exportedListReadyMoment'), {
                    classes: ['text--white'],
                    color: 'info',
                    timeout: 0,
                    icon: 'check',
                    iconColor: 'white',
                    x: 'center',
                    y: 'top'
                })
                let data = []
                const selectedItemsForExport = this.selectedStock.length > 0 ? this.selectedStock : this.filteredItems
                selectedItemsForExport.forEach(item => {
                    if(report === 'available'){
                        const itemObj = {
                            age: item.Stock.age,
                            bundleNo: item.Stock.bundleNo,
                            buyingPriceLocal: item.Stock.costPriceLocal,
                            claim: item.Stock.certification,
                            currency: item.Stock.currencyLocal,
                            currentBuyingPriceLocal: item.Stock.costPriceCurrentLocal,
                            grade: item.Stock.grade,
                            length: item.Stock.length,
                            product: item.Stock.product,
                            quantity: item.Stock.quantity,
                            sellingCurrency: item.Stock.currencySelling,
                            species: item.Stock.species,
                            thickness: item.Stock.thickness,
                            uofmVolume: item.Stock.uofmVolume,
                            volume: item.Stock.volume,
                            width: item.Stock.width
                        }
                        this.priceMargins.forEach(pm => {
                            itemObj['sellingPrice' + parseInt(pm.PriceMargin.margin).toString()] = item.Stock.suggestedSellingPrice['pm_' + parseInt(pm.PriceMargin.margin).toString()]
                        })
                        data.push(itemObj)
                    } else {
                        const sellingPrice =  item.Stock.suggestedSellingPrice[priceMargin]
                        const itemObj = {
                            age: item.Stock.age,
                            bundleNo: item.Stock.bundleNo,
                            // buyingPriceLocal: item.Stock.costPriceLocal,
                            claim: item.Stock.certification,
                            currency: item.Stock.currencyLocal,
                            sellingCurrency: item.Stock.currencySelling,
                            currentBuyingPriceLocal: item.Stock.costPriceCurrentLocal,
                            grade: item.Stock.grade,
                            length: item.Stock.length,
                            product: item.Stock.product,
                            quantity: item.Stock.quantity,
                            sellingPrice: sellingPrice,
                            species: item.Stock.species,
                            thickness: item.Stock.thickness,
                            uofmVolume: item.Stock.uofmVolume,
                            volume: item.Stock.volume,
                            width: item.Stock.width
                        }
                        data.push(itemObj)
                    }
                })

                let exportedFile = await api.post(url, {
                    data: data,
                    fields: headers,
                    type: reportType,
                    warehouse: this.filterValues.warehouse
                }, {
                    responseType: 'blob'
                })
                const fileUrl = window.URL.createObjectURL(new Blob([exportedFile.data], {type: reportFileType}));
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank'
                link.setAttribute('download', reportFileName + reportFileExtension);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(fileUrl);

                //close notification
                let cmp = this.$toast.getCmp()
                cmp.close()
            } catch(error){
                this.$toast.error(this.$t('message.errors.couldNotExportList') + ": " + error, {
                    timeout: 7000,
                    x: 'center',
                    y: 'top'
                })
            }
        },
        fetch() {
            return new Promise((resolve, reject) => {
                try {
                    this.loadMasterList()
                        .then(() => {
                            this.loadFilterOptions()
                                .then(() => {
                                    resolve('done')
                                })
                                .catch((e) => {
                                    reject(e)
                                })
                        })
                        .catch((e) => {
                            reject(e)
                        })
                } catch(e) {
                    reject(e)
                }
            })
        },
        fetchReport() {
            this.loading.masterList = true
            this.clearFilterOptions()
                .then(() => {
                    this.fetch()
                        .then(() => {
                            this.filterList()
                                .then(() => {
                                    this.calculateTotalVolumes()
                                        .then(() => {
                                            this.loading.masterList = false
                                        })
                                        .catch(() => {
                                            this.loading.masterList = false
                                        })
                                })
                                .catch((error) => {
                                    this.loading.masterList = false
                                    this.$toast.error(error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.loading.masterList = false
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
                .catch((error) => {
                    this.loading.masterList = false
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        filterList(column = null, value = []) {
            return new Promise((resolve, reject) => {
                if(!this.loading.masterList) this.loading.masterList = true
                try {
                    this.filterValues[column] = value
                    let filterFunctions = []
                    for (const field in this.filterValues) {
                        if (field !== 'warehouse') {
                            if (this.filterValues[field] != null && this.filterValues[field].length > 0) {
                                filterFunctions.push((item) => {
                                    return this.filterValues[field].includes(item['Stock'][field])
                                })
                            }
                        }
                    }
                    if (filterFunctions.length > 0) {
                        this.filteredItems = this.items.filter(item => filterFunctions.every(f => f(item)))
                    } else {
                        this.filteredItems = this.items
                    }
                    resolve('done')
                } catch (e) {
                    reject(e)
                }
            })
        },
        formatDate,
        getPriceMargins() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/price-margin')
                    .then((response) => {
                        const priceMargins = response.data.data
                        resolve(priceMargins)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getStockAge(ageDays) {
            let age = 'fresh'
            if(ageDays > 180) age = '180+'
            return age
        },
        getUpdatedExRate() {
            let warehouseCurrency = 'MYR'
            if(this.filterValues.warehouse === 'ID') warehouseCurrency = 'IDR'
            const currencies = {
                source: 'USD',
                target: warehouseCurrency
            }
            api
                .put('/reports/stocks/user-exchange-rate', {
                    currencies: currencies
                })
                .then((response) => {
                    if(response.data.status === 'success') {
                        if (this.filterValues.warehouse === 'MY') this.currentExchangeRates__MY = response.data.rate
                        if (this.filterValues.warehouse === 'ID') this.currentExchangeRates__ID = response.data.rate
                        this.currentExchangeRates__lastUpdate = response.data.date
                    } else {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        getStockExRate() {
            return new Promise((resolve, reject) => {
                let toCurrency = 'MYR'
                if(this.filterValues.warehouse === 'ID') toCurrency = 'IDR'
                    const conditions = [
                    {field: 'from_currency', value: 'USD'},
                    {field: 'to_currency', value: toCurrency},
                ]
                api
                    .get("/reports/stocks/user-exchange-rate", {
                        params: {
                            conditions: conditions
                        }
                    })
                    .then(response => {
                        if(response.data.status === 'success') {
                            if(this.filterValues.warehouse === 'MY') this.currentExchangeRates__MY = response.data.data[0]['exrate']
                            if(this.filterValues.warehouse === 'ID') this.currentExchangeRates__ID = response.data.data[0]['exrate']
                            this.currentExchangeRates__lastUpdate = response.data.data[0]['rate_date']
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        handleResize() {
            this.tableHeight = window.innerHeight - 225;
            return true
        },
        loadFilterOptions() {
            return new Promise((resolve, reject) => {
                try {
                    this.headers.forEach(header => {
                        if (header.searchable) {
                            const option = header.value
                            const [a, b] = option.split('.')
                            let options = this.items.map(item => item[a][b])
                            if (options.length > 0) {
                                options.filter((item, index) => options.indexOf(item) === index)
                                options.filter(Boolean)
                                options.sort()
                                this.filterOptions[b] = options
                            }
                        }
                    })
                    resolve('done')
                } catch(e) {
                    reject(e)
                }
            })
        },
        loadMasterList() {
            return new Promise((resolve, reject) => {
                const conditions = [
                    {
                        field: 'stockorders.stock_country_id',
                        value: this.filterValues.warehouse
                    }
                ]
                const exRate = {
                    source: "USD",
                    target: this.filterValues.warehouse == 'MY' ? "MYR" : "IDR",
                    rate: this.filterValues.warehouse == 'MY' ? this.currentExchangeRates__MY : this.currentExchangeRates__ID
                }
                api
                    .get("/reports/stocks/available",{
                        params: {
                            conditions: conditions,
                            exrate: exRate
                        }
                    })
                    .then(response => {
                        this.items = response.data.data
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        numberFormat,
        openReserveDialog(stockId, stockBundleNo, stockReservedBuyerId) {
            this.reserveDialog = true
            this.selectedStockId = stockId
            this.selectedStockBundleNo = stockBundleNo
            this.selectedStockReservedBuyerId = stockReservedBuyerId
            if(stockReservedBuyerId != null) {
                this.updateReserve = true
            } else {
                this.updateReserve = false
            }
        },
        openTotalsDialog () {
            this.totalsDialog = true
        },
        reserveDialogClosed() {
            this.reserveDialog = false;
        },
        resetValues() {
            this.loading.masterList = true
            this.resetWareHouseValues()
                .then(() => {
                    this.fetch()
                        .then(() => {
                            this.filterList()
                                .then(() => {
                                    this.calculateTotalVolumes()
                                        .then(() => {
                                            this.loading.masterList = false
                                        })
                                        .catch(() => {
                                            this.loading.masterList = false
                                        })
                                })
                                .catch(() => {
                                    this.loading.masterList = false
                                })
                        })
                        .catch(() => {
                            this.loading.masterList = false
                            this.$toast.error(this.$t('message.errors.reportNotLoaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
        },
        stockReserved(stockObj) {
            const stockId = stockObj.stockId
            const reservedBuyer = stockObj.reservedBuyer
            const reservedBuyerId = stockObj.reservedBuyerId
            const index = this.filteredItems.findIndex(item => item.Stock.id == stockId)
            if(index){
                this.filteredItems[index]['Stock']['reservedBuyer'] = reservedBuyer
                this.filteredItems[index]['Stock']['reservedBuyerId'] = reservedBuyerId
            }
            //update filter options for reserved
            const options = this.items.map(item => item['Stock']['reservedBuyer'])
            if (options.length > 0) {
                options.filter((item, index) => options.indexOf(item) === index)
                options.filter(Boolean)
                options.sort()
                this.filterOptions['reservedBuyer'] = options
            }
            this.reserveDialog = false
        },
        totalsDialogClosed () {
            this.totalsDialog = false;
        }
    },
    watch: {
        tableFooterHeight() {
            this.handleResize()
        },
        'filterValues.warehouse'(newVal) {
            this.stockReportsCurrentWarehouse = newVal
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if(this.stockReportsCurrentWarehouse){
            this.filterValues.warehouse = this.stockReportsCurrentWarehouse
        } else {
            if(this.User.country_id === 1) this.filterValues.warehouse = 'MY'
            if(this.User.country_id === 4) this.filterValues.warehouse = 'ID'
            if(this.User.country_id === 3) this.filterValues.warehouse = 'VN'
            if(this.User.country_id === 2) this.filterValues.warehouse = 'TH'
        }
        if(this.filterValues.warehouse == null) this.filterValues.warehouse = 'MY'
        this.handleResize()
        this.getStockExRate()
        this.getPriceMargins()
            .then((priceMargins) => {
                this.priceMargins = priceMargins
                this.fetchReport()
            })
            .catch((error) => {
                this.$toast.error(error,
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.col-1_5 {
    width: 12.5% !important;
    max-width: 12.5% !important;
}
.col-10_5 {
    width: 87.5% !important;
    max-width: 87.5% !important;
}
.filter-width-75 {
    width: 75px !important;
    max-width: 75px !important;
}
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.bg-lightblue {
    background-color: lightblue;
}
.bg-lightorange {
    background-color: #FFCC80;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep .v-data-table__wrapper {
    overflow-x: auto;
}
.v-data-table::v-deep  thead > tr > th:nth-child(1) {
    min-width: unset !important;
    background-color: #c5e1a5;
    width: 1px !important;
}
.v-data-table::v-deep .v-data-table-header th {
    white-space: nowrap;
}
.stock-filters {
    overflow-y: auto;
    height: var(--table-height)
}
</style>